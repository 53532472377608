<template>
  <v-container fluid>
    <layout :back="true" />
    <v-form ref="form" v-model="formValid" lazy-validation>
      <div class="mx-4">
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h3 class="font-weight-black">{{ $t("profile.user") }}</h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 mx-0 mt-2" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">{{
              $t("profile.pharmacygroup_new_user")
            }}</v-subheader>
            <v-text-field
              :label="$t('field.email')"
              hide-details="auto"
              outlined
              class="mt-3"
              v-model="user.email"
              validate-on-blur
              :rules="emailRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="mx-4 mt-6">
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h3 class="font-weight-black">{{ $t("profile.new_pharmacy") }}</h3>
          </v-col>
        </v-row>
        <!-- pharmacy base data-->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">{{
              $t("field.web")
            }}</v-subheader>
            <v-text-field
              :label="`${$t('field.web')} (http://)`"
              hide-details="auto"
              outlined
              v-model="pharmacy.web"
              validate-on-blur
              :rules="web_page"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.employee_number") }}</v-subheader
            >
            <v-text-field
              name="input-10-1"
              v-model="pharmacy.employees_number"
              :label="$t('field.employee_number')"
              v-mask="'#########'"
              hide-details="auto"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.pharmacist_name") }}</v-subheader
            >
            <v-text-field
              :label="$t('field.full_name')"
              v-model="pharmacy.pharmacy_owner.full_name"
              hide-details="auto"
              outlined
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >
              {{ $t("field.contact_phone_number") }}<span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacy.pharmacy_owner.phone_number"
              @updatePhoneNumber="updateOwnerPhoneNumber"
              @updateCountry="updatecountryOwnerTelephone"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="mt-6"
              v-model="contact"
              @click="changeValidation"
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("contact.add") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-if="contact">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.contact_name") }}</v-subheader
              >
              <v-text-field
                :label="$t('field.contact_name')"
                v-model="pharmacyContact.full_name"
                hide-details="auto"
                outlined
                :rules="contact ? required : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                >
                {{ $t("field.contact_phone_number") }}<span class="red--text text-subtitle-2">*</span>
              </v-subheader>
              <vue-tel-input
                :requiredPass="contact"
                :phoneNumber="pharmacyContact.phone_number"
                @updatePhoneNumber="updateContactPhoneNumber"
                @updateCountry="updatecountryContactTelephone"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.contact_email") }}</v-subheader
              >
              <v-text-field
                v-model="pharmacyContact.email"
                :label="$t('field.contact_email')"
                hide-details="auto"
                outlined
                :rules="contact ? required : []"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">
              {{ $t("register.pharmacy_data") }}
            </h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.commercial_name") }}</v-subheader
            >
            <v-text-field
              v-model="pharmacy.commercial_name"
              name="input-10-1"
              :label="$t('field.commercial_name')"
              hide-details="auto"
              outlined
              validate-on-blur
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.cif") }}</v-subheader
            >
            <v-text-field
              :label="$t('field.cif_format')"
              v-model="pharmacy.cif"
              :rules="cif"
              validate-on-blur
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              {{ $t("field.pharmacy_number") }}</v-subheader
            >
            <v-text-field
              v-model="pharmacy.number"
              :label="$t('field.pharmacy_number')"
              color="primary"
              hide-details="auto"
              v-mask="'#########'"
              validate-on-blur
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              <!-- <span class="red--text text-subtitle-2">*</span> -->
              {{ $t("field.soe") }}</v-subheader
            >
            <v-text-field
              v-model="pharmacy.soe"
              :label="$t('field.soe')"
              color="primary"
              hide-details="auto"
              v-mask="'#########'"
              outlined
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >
              {{ $t("field.phone_number") }}<span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacy.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateCountry="updatecountryPharmacyTelephone"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.address") }}</v-subheader
            >
            <vuetify-google-autocomplete
              ref="address"
              id="map2"
              classname="form-control"
              :label="$t('field.address_placeholder')"
              outlined
              placeholder=""
              :value="pharmacy.address.formatted"
              hide-details
              country="es"
              v-on:placechanged="getAddressData"
              validate-on-blur
              :rules="required"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mb-2" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <span class="text-subtitle-1">{{ $t("field.opening_hours") }}</span>
          </v-col>
        </v-row>
        <v-row
          class="pa-0 ma-0"
          justify-sm="center"
          v-for="(hours, index) in openHours"
          :key="index"
        >
          <v-col cols="6" sm="4" md="2" class="pa-0 ml-2 text-h6">
            <h5 class="p-2 mb-1 tertiary--text">- {{ hours.name }}</h5>
          </v-col>
          <v-col cols="5" sm="4" md="2" class="pa-0 ml-2 text-h6">
            <v-switch
              class="pa-0 ma-0 mt-1"
              hide-details="auto"
              dense
              color="success"
              v-model="pharmacy.open_hour"
              :value="hours.id"
              name="horario"
              validate-on-blur
              :rules="required"
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1 font-weight-normal">
                  {{
                    pharmacy.open_hour == hours.id
                      ? $t("operation.open")
                      : $t("operation.close")
                  }}
                </div>
              </template></v-switch
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox class="mt-6" v-model="pharmacy.billing_match_data">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("field.pharmacy_match_billing") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-if="!pharmacy.billing_match_data">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text">{{
                $t("field.fiscal_name")
              }}</v-subheader>
              <v-text-field
                name="input-10-1"
                v-model="pharmacy.fiscal_name"
                :label="$t('field.fiscal_name')"
                hide-details="auto"
                validate-on-blur
                :rules="required"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                ><span class="red--text text-subtitle-2">*</span
                >{{ $t("field.fiscal_address") }}</v-subheader
              >
              <vuetify-google-autocomplete
                ref="address"
                :id="'map3'"
                placeholder=""
                classname="form-control"
                :label="$t('field.address_placeholder')"
                :value="pharmacy.billing_address.formatted"
                outlined
                hide-details
                v-on:placechanged="getBillingAddressData"
                country="es"
                validate-on-blur
                :rules="required"
              >
              </vuetify-google-autocomplete>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="pa-0 ma-0 mt-2">
          <span class="text-subtitle-1 pl-2">{{
            $t("field.pharmacy_logo")
          }}</span>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="7" sm="8" md="4" class="pa-0">
              <v-btn
                color="secondary"
                class="mt-2"
                block
                outlined
                large
                rounded
                @click="addlogo"
                style="text-transform: none"
              >
                {{ $t("register.pharmacy_logo") }}
                <v-icon right dark> mdi-paperclip </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-file-input
            small-chips
            id="logo"
            :label="$t('field.logo')"
            truncate-length="15"
            v-model="pharmacy.logo"
            class="pa-0"
            :class="pharmacy.logo != null ? '' : 'd-none'"
            accept="image/*"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="pa-0 mt-2 ml-2">
          <span>{{ $t("video.add") }}:</span>
          <h5 class="p-2 mt-2 secondary--text">- {{ $t("video.q1") }}</h5>
          <h5 class="p-2 mt-1 secondary--text">- {{ $t("video.q2") }}</h5>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="10" sm="8" md="4" class="pa-0 ma-0">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="8" sm="8" md="4" class="pa-0">
              <v-btn
                color="secondary"
                class="mt-2"
                block
                large
                outlined
                rounded
                @click="addVideo"
                style="text-transform: none"
              >
                {{ $t("video.attach") }}
                <v-icon right dark> mdi-paperclip </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-file-input
            small-chips
            :class="pharmacy.video != null ? '' : 'd-none'"
            id="video"
            :label="$t('video.attach')"
            truncate-length="15"
            v-model="pharmacy.video"
            accept="video/*"
          ></v-file-input>
        </v-col>
      </v-row> -->
    </v-form>
    <v-row class="mx-2 my-8" justify="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-center">
        <div>
          <v-btn
            color="transparent"
            large
            rounded
            class="customButton primary--text"
            @click="$router.go(-1)"
          >
            {{ $t("offer.cancel") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 text-center">
        <div>
          <v-btn
            color="secondary"
            large
            :disabled="
              !formValid || !phoneValid || !phoneValid2 || !phoneValid3
            "
            rounded
            @click="updatePharmacy"
          >
            {{ $t("operation.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import AddressDataHelper from "@/helpers/AddressData.js";
import { mapActions, mapGetters } from "vuex";
import VueTelInput from "@/components/shared/VueTelInput.vue";
import _ from "lodash";

export default {
  components: {
    Layout,
    VuetifyGoogleAutocomplete,
    VueTelInput,
  },
  mounted() {
    this.formValid = false;
  },
  data() {
    return {
      requiredDefault: true,
      countryOwnerTelephone: null,
      countryContactTelephone: null,
      countryPharmacyTelephone: null,
      myPhone: "",
      formValid: false,
      phoneValid: true,
      phoneValid2: true,
      phoneValid3: true,
      phone2: {
        number: "",
        valid: false,
        country: undefined,
      },
      pharmacy: {
        open_hour: null,
        number: null,
        address: {},
        commercial_name: null,
        fiscal_name: null,
        phone_number: null,
        web: null,
        id: null,
        employees_number: null,
        billing_match_data: false,
        billing_address: {},
        nif: null,
        soe: null,
        pharmacy_contact: {},
        pharmacy_owner: {
          full_name: null,
          phone_number: null,
        },
        contacts: [],
      },
      country_code: null,
      pharmacyEx: {
        number: "",
        valid: false,
        country: undefined,
      },
      pharmacyContact: {
        full_name: null,
        phone_number: null,
        email: null,
      },
      user: {
        email: null,
      },
      phone_number: null,
      contact: false,
      pharmacy_phone_number: null,
      pharmacy_code: null,
      contact_phone_number: null,
      contact_code: null,
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.phone_number"),
      ],
      cif: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$)|(^\d{8}[a-zA-Z]{1}$)/.test(v) ||
          this.$i18n.t("validation.cif"),
      ],

      emailRules: [
        (v) => {
          return (
            !v ||
            /(.+@.+\.[\w\d]+$)/.test(v) ||
            this.$i18n.t("validation.email_format")
          );
        },
      ],
      web_page: [
        (v) =>
          !v ||
          /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
            v
          ) ||
          this.$i18n.t("validation.web_page"),
      ],
      code: [
        {
          name: "España (+34)",
          icon: "",
          code: "+34 ",
        },
      ],
    };
  },
  async created() {
    this.enableLoading();
    this.getOpenHours();
    await this.getPharmacyGroup(this.groupId);
    this.formatData();
    this.disableLoading();
    //this.formatPhone()
  },
  computed: {
    ...mapGetters({ openHours: "core/openHours" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ groupId: "user/groupId" }),
    ...mapGetters({ _pharmacyGroup: "pharmacy/pharmacyGroup" }),
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getOpenHours: "core/getOpenHours" }),
    ...mapActions({ getPharmacyGroup: "pharmacy/getPharmacyGroup" }),
    ...mapActions({ createPharmacy: "pharmacy/createPharmacy" }),
    ...mapActions({ generateUser: "user/generateUser" }),

    changeValidation() {
      this.phoneValid2 = !this.phoneValid2;
      if (this.contact == false) {
        this.pharmacyContact.full_name = null;
        this.pharmacyContact.phone_number = null;
        this.pharmacyContact.email = null;
      }
    },
    updatecountryOwnerTelephone(country) {
      this.countryOwnerTelephone = "+" + country;
    },
    updatecountryContactTelephone(country) {
      this.countryContactTelephone = "+" + country;
    },
    updatecountryPharmacyTelephone(country) {
      this.countryPharmacyTelephone = "+" + country;
    },
    async formatData() {
      let pg = _.cloneDeep(this._pharmacyGroup);
      // this.pharmacy.pharmacy_owner.full_name = pg.representative;
      this.pharmacy.pharmacy_owner.phone_number = pg.phone_number;
      this.pharmacy.phone_number = pg.phone_number;
      this.pharmacy.billing_address = pg.billing_address;
      this.pharmacy.fiscal_name = pg.fiscal_name;
      this.pharmacy.cif = pg.cif;

      // Object.assign(this.pharmacy);
      // this.pharmacy;
      // this.pharmacy.contacts = JSON.parse(
      //   JSON.stringify(this.pharmacy.contacts)
      // );
      // if (this.pharmacy.contacts.length == 0) {
      //   await this.pharmacy.contacts.push({
      //     full_name: null,
      //     phone_number: null,
      //     email: null,
      //   });
      // }
      // this.pharmacy.pharmacy_owner = JSON.parse(
      //   JSON.stringify(this.pharmacy.pharmacy_owner)
      // );
      // if (this.pharmacy.contacts[0].full_name != null) {
      //   this.contact = true;
      // }
      // if (this.pharmacy.fiscal_name == this.pharmacy.commercial_name) {
      //   this.billing_match_data = true;
      // }
      // this.pharmacy.open_hour = this.pharmacy.open_hour.id;
      // //this.formatPhones();
    },
    updateFormValid(valid) {
      this.phoneValid = valid;
    },
    updateFormValid2(valid) {
      this.phoneValid2 = valid;
    },
    updateFormValid3(valid) {
      this.phoneValid3 = valid;
    },
    updateOwnerPhoneNumber(phone) {
      this.pharmacy.pharmacy_owner.phone_number = phone;
    },
    updateContactPhoneNumber(phone) {
      this.pharmacyContact.phone_number = phone;
    },
    updatePhoneNumber(phone) {
      this.pharmacy.phone_number = phone;
    },
    phone() {
      this.pharmacy.phone_number = this.country_code.concat(
        "",
        this.phone_number
      );
    },
    addlogo() {
      document.getElementById("logo").click();
    },
    addVideo() {
      document.getElementById("video").click();
    },
    getAddressData(addressData, placeResultData) {
      this.pharmacy.address = AddressDataHelper.addressData(
        addressData,
        placeResultData
      );
    },
    getBillingAddressData(addressData, placeResultData) {
      this.pharmacy.billing_address = AddressDataHelper.addressData(
        addressData,
        placeResultData
      );
    },
    RefactorSendPharmacy() {
      this.pharmacy.pharmacy_owner.pharmacy = this.pharmacy.id;
      if (
        this.pharmacyContact.length &&
        this.pharmacyContact.full_name != null
      ) {
        if (this.contact == false) {
          this.pharmacyContact.full_name == null;
          this.pharmacyContact.full_name == null;
          this.pharmacyContact.full_name == null;
        }
      } else {
        this.pharmacyContact.full_name == null;
        this.pharmacyContact.full_name == null;
        this.pharmacyContact.full_name == null;
      }
      if (!this.pharmacy.commercial_name)
        this.pharmacy.commercial_name = this.pharmacy.pharmacy_owner.full_name;
      if (this.pharmacy.billing_match_data) {
        this.pharmacy.billing_address = this.pharmacy.address;
        this.pharmacy.fiscal_name = this.pharmacy.commercial_name;
      }
    },
    formatPhones() {
      this.country_code = this.PharmacyData.phone_number.substr(
        0,
        this.PharmacyData.phone_number.length - 9
      );
      this.country_code = this.country_code.concat(" ");
      this.phone_number = this.PharmacyData.phone_number.substr(
        this.PharmacyData.phone_number.length - 9
      );
      // pharmacy number
      this.pharmacy_code = this.PharmacyData.pharmacy_owner.phone_number.substr(
        0,
        this.PharmacyData.pharmacy_owner.phone_number.length - 9
      );
      this.pharmacy_code = this.pharmacy_code.concat(" ");
      this.pharmacy_phone_number =
        this.PharmacyData.pharmacy_owner.phone_number.substr(
          this.PharmacyData.pharmacy_owner.phone_number.length - 9
        );
      // contact phone
      if (this.pharmacyContact.full_name != null) {
        this.contact_code = this.pharmacyContact.phone_number.substr(
          0,
          this.pharmacyContact.phone_number.length - 9
        );

        this.contact_code = this.contact_code.concat(" ");
        this.contact_phone_number = this.pharmacyContact.phone_number.substr(
          this.pharmacyContact.phone_number.length - 9
        );
      }
    },
    async updatePharmacy() {
      if (this.$refs.form.validate()) {
        if (this.user.email) {
          let [status, data] = await this.generateUser({
            email: this.user.email,
          });
          if (status == 201) {
            this.pharmacy.user = data;
          }
        }
        await this.RefactorSendPharmacy();
        if (this.pharmacy.pharmacy_owner) 
        await this.formatOwnerTelephone();
        await this.formatContactTelephone();
        await this.formatPharmacyTelephone();
        await this.enableLoading();
        if (!this.pharmacy.open_hour) {
          this.$notify({
            title: this.$i18n.tc("notification.error_open_hours", 1),
            text: this.$i18n.tc("notification.error_open_hours", 1),
            type: "error",
          });
          this.disableLoading();
          return;
        }
        this.pharmacy.soe = this.pharmacy.soe || null;
        this.pharmacy.group = this.groupId;
        this.pharmacy.pharmacy_contact =
          this.pharmacyContact.full_name != null ? this.pharmacyContact : {};
        try {
          await this.createPharmacy(this.pharmacy);
          this.$notify({
            title: this.$i18n.t("notification.profile_update"),
            text: this.$i18n.t("notification.profile_update"),
            type: "success",
          });
          this.$router.push({ name: "profile" });
        } catch (error) {
          Object.values(error.response.data).forEach((elm) => {
            this.$notify({
              title: this.$i18n.tc("notification.profile_update_error", 1),
              text: elm.join(","),
              type: "error",
            });
          });
        } finally {
          this.disableLoading();
        }
      }
    },
    formatPhone() {
      //let listPhone = this.phoneNumber.split(" ")
      /* if (this.fields.phone_number.startsWith("+")){
        let listPhone = this.fields.phone_number.split(" ")
        listPhone.shift()
        this.fields.phone_number = listPhone.join("")
      } */
    },
    formatOwnerTelephone() {
      let phone = this.pharmacy.pharmacy_owner.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacy.pharmacy_owner.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.pharmacy_owner.phone_number = country + " " + phone2;
      } else {
        let tel =
          this.countryOwnerTelephone +
          this.pharmacy.pharmacy_owner.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.pharmacy_owner.phone_number = country + " " + phone2;
      }
    },
    formatContactTelephone() {
      if (!this.pharmacyContact.phone_number) return;
      let phone = this.pharmacyContact.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacyContact.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacyContact.phone_number = country + " " + phone2;
      } else {
        let tel =
          this.countryContactTelephone + this.pharmacyContact.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacyContact.phone_number = country + " " + phone2;
      }
    },
    formatPharmacyTelephone() {
      let phone = this.pharmacy.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacy.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.phone_number = country + " " + phone2;
      } else {
        let tel = this.countryPharmacyTelephone + this.pharmacy.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.phone_number = country + " " + phone2;
      }
    },
  },
};
</script>
